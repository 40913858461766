.welcome {
  text-align: left;
}
.welcome h2,
.welcome h3,
.welcome h4 {
  color: #eee;
  font-family: "Cinzel", serif;
  font-size: 150%;
}
.welcome .tagline {
  font-size: 200%;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0;
}
