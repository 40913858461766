@import url(https://fonts.googleapis.com/css2?family=Alice&family=Cinzel&family=Mooli&family=Philosopher&family=Satisfy&family=Shadows+Into+Light&display=swap);
* {
  box-sizing: border-box;
}
:root {
  /* Define a few values for general use */
  --pad: 0.5rem; /* generic padding */
  --bd: 3px; /* border size */
}

html {
  height: 100%;
  background: #090a0f radial-gradient(ellipse at top, #1b2735 0%, #090a0f 90%)
    no-repeat;
  /* background: url("https://i.imgur.com/BKruPI8.png") 50% 50% no-repeat; */
  color: #cdcdcd;
  display: grid;
  place-items: center;
}
body {
  background-blend-mode: overlay;
  font-size: calc(0.625vmin + 0.75rem); /* Responsive font */
  /*
  font-family: "Alice", serif;
  font-family: "Cinzel", serif;
  font-family: "Philosopher", sans-serif;
  font-family: "Satisfy", cursive;
  font-family: "Shadows Into Light", cursive;
  */
  font-family: "Mooli", sans-serif;
  margin: 0; /* Remove HTML default spacing */
  min-width: 100%;
  max-width: 100%;
  text-align: center;
}
h1,
h2 {
  color: #eee;
  font-family: "Cinzel", serif;
  font-size: 250%;
  line-height: 1;
  margin-bottom: 0;
}
#root {
  display: inline-block;
  margin: 0 auto;
  overflow-x: hidden;
  padding: calc(2 * var(--pad));
  text-align: center;
}
.App {
  /* max-width: 1280px; */
  max-width: 100vw;
  width: 100%;
  text-align: center;
}
.logo {
  font-family: "Alice", serif;
  font-size: 200%;
}
.tagline {
  color: #aaa;
  font-family: "Satisfy", cursive;
  font-size: 100%;
  padding-bottom: 20px;
}
.center {
  text-align: center;
}
.text-lg {
  font-size: 125%;
}
.subtle {
  opacity: 0.6;
}
.col-6 {
  display: inline-grid;
  width: 50%;
}
@media screen and (max-width: 480px) {
  .col-6 {
    display: block;
    width: 100%;
  }
}
a,
button {
  cursor: pointer;
}
a {
  text-decoration: none;
}
a:link {
  color: #68a9ea;
}
a:visited {
  color: #dd7575;
}
footer {
  display: block;
  width: 100%;
  min-width: 96vw;
  max-width: 100vw;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    #12100e 64%,
    #090a0f 90%
  );
  max-height: 40vh;
  min-height: 10vh;
  font-size: 85%;
  margin: 20vh auto 0 auto;
  padding-bottom: 20px;
  padding-top: 30px;
}
.name_field {
  font-size: 15pt;
}
.tagline {
  color: #aaa;
}
.plan-board {
  text-align: left;
}
.plan-board li {
  padding-bottom: 20px;
}
.overlay {
  background: rgba(0, 0, 0, 0.2);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vh;
  height: 100vh;
}
.overlay-modal {
  margin: auto auto;
  display: block;
  overflow: auto;
  width: 60vh;
  height: 60vh;
}
.version {
  font-size: 50%;
  color: #2b4162;
}
.hide,
.print {
  display: none;
}
@media print {
  .logo.hide,
  .print {
    display: inline-block;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 60px;
}
.navIcon {
  background-size: contain;
  border-bottom: 2px solid #ffffff;
  margin: 20px auto;
  max-width: 100%;
  width: 80%;
  height: 45%;
}
#NavWelcome .navIcon {
  background:
    url(/static/media/come.8a27fba5.png) 50% 50% no-repeat,
    radial-gradient(ellipse, #1b2735 0%, rgba(0, 0, 0, 0) 50%) no-repeat;
  background-size: contain;
}
#NavDarot .navIcon {
  background:
    url(/static/media/meditation.b8306ccc.png) 50% 50% no-repeat,
    radial-gradient(ellipse, #1b2735 0%, rgba(0, 0, 0, 0) 50%) no-repeat;
  background-size: contain;
}
#NavCharacter .navIcon {
  background:
    url(/static/media/archetype.95bc610c.png) 50% 50% no-repeat,
    radial-gradient(ellipse, #1b2735 0%, rgba(0, 0, 0, 0) 50%) no-repeat;
  background-size: contain;
}
#NavBoards .navIcon {
  background:
    url(/static/media/plan.4f8e2811.png) 50% 50% no-repeat,
    radial-gradient(ellipse, #1b2735 0%, rgba(0, 0, 0, 0) 50%) no-repeat;
  background-size: contain;
}
@media screen and (max-width: 1280px) {
  nav.nav {
    zoom: 0.85;
  }
  .nav.zoom {
    zoom: 0.5;
  }
}
@media screen and (max-width: 960px) {
  nav.nav {
    zoom: 0.65;
  }
  .nav.zoom {
    zoom: 0.4;
  }
}
@media screen and (max-width: 800px) {
  nav.nav {
    zoom: 0.6;
  }
}
@media screen and (max-width: 700px) {
  nav.nav {
    zoom: 0.5;
  }
}
@media screen and (max-width: 640px) {
  nav.nav {
    zoom: 0.45;
  }
}
@media screen and (max-width: 520px) {
  nav.nav {
    zoom: 0.4;
  }
}
@media screen and (max-width: 480px) {
  nav.nav {
    zoom: 0.35;
  }
  .nav.zoom {
    zoom: 0.25;
  }
}
@media screen and (max-width: 320px) {
  nav.nav {
    zoom: 0.3;
  }
  .nav.zoom {
    zoom: 0.25;
  }
}

.nav.zoom .door-mat {
  display: none;
}
.homeLogo {
  color: #ccc;
  font-family: "Alice", serif;
  font-size: 300%;
  width: 25%;
}
/*
.nav.zoom .homeLogo {
  display: none;
}
.nav.zoom:before {
  color: #ccc;
  content: "HH";
  display: inline-block;
  font-family: "Alice", serif;
  font-size: 600%;
  text-align: center;
  width: 100%;
}
*/
.nav-items {
  list-style: none;
  display: flex;
  text-align: center;
  width: auto;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  padding-left: 0;
}
.nav-items > li {
  cursor: pointer;
  display: inline-flex;
  position: relative;
}

.door {
  position: relative;
  width: 170px;
  height: 270px;
  margin: 0 30px;
  cursor: pointer;
  transform-style: preserve-3d;
  transform: perspective(2500px);
  box-shadow:
    0 1px 0 0 rgba(255, 192, 0, 0.25),
    0 -1px 0 0 rgba(255, 64, 0, 0.25),
    1px 0 0 0 rgba(255, 192, 0, 0.25),
    -1px 0 0 0 rgba(255, 64, 0, 0.25),
    1px -1px 0 0 rgba(255, 128, 0, 0.5),
    -1px 1px 0 0 rgba(255, 128, 0, 0.5),
    1px 1px 0 0 rgba(255, 255, 0, 0.75),
    -1px -1px 0 0 rgba(255, 0, 0, 0.75);
}
.door-front {
  width: 170px;
  height: 270px;
  overflow: hidden;
  transform-origin: left;
  box-shadow: 30px 0 50px rgba(0, 0, 0, 0.2);
  position: absolute;
  background-color: #6a4317;
  z-index: 1;
  transition: 0.5s;
}
.door-front:before,
.door-front:after {
  content: "";
  position: absolute;
  background-color: #603c14;
  width: 105px;
  height: 75px;
  border: 8px ridge #583523;
  left: 24px;
  rotate: 180;
}

.door-front:before {
  top: 25px;
}

.door-front:after {
  top: 155px;
}
.knob {
  position: absolute;
  width: 20px;
  height: 30px;
  background-color: #dae1ec;
  top: 122px;
  left: 145px;
  border-radius: 2px;
}

.knob:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  background-color: #e5e6d6;
  width: 18px;
  height: 18px;
  left: -1px;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}

.knob:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 7px;
  background-color: #333;
  top: 20px;
  left: 8.5px;
}

.door:hover .door-front {
  transform: rotateY(-160deg);
}

.door-back {
  position: relative;
  background: linear-gradient(
    0deg,
    rgb(67, 21, 16) 13%,
    rgba(73, 88, 103, 0.5) 13%,
    rgba(79, 100, 124, 0.5) 15%,
    rgba(2, 14, 30, 0.756) 15%
  );
  color: #eee;
  font-size: 125%;
  font-weight: bold;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 10px solid #192430;
}

.door-back.door-back-trans-1 {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
}

.door-back.door-back-trans-2 {
  background: linear-gradient(-50deg, #e9ef3a, #a06bb9, #165b22, #b4820b);
  background-size: 500% 500%;
  animation: gradient 10s ease infinite;
}

.door-back.door-back-trans-3 {
  background: linear-gradient(-40deg, #4e060c, #d7d04f, #27052d, #0d0534);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
}
.door-back:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  content: " ";
  width: 100%;
  height: 100%;
  background: radial-gradient(
      ellipse 96% 20% at top,
      rgba(178, 217, 230, 0.8) 0%,
      rgba(158, 197, 210, 0) 50%
    )
    no-repeat;
  background-size: contain;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.door-mat {
  position: relative;
  perspective: 200px;
}

.door-mat:before {
  content: "Welcome";
  position: absolute;
  width: 170px;
  height: 23px;
  padding-top: 7px;
  color: rgba(180, 180, 180, 0.5);
  font-family: "Alice", serif;
  font-weight: bold;
  text-shadow: 0 0 2px #111;
  background: repeating-linear-gradient(
    #323132,
    #242427 10px,
    #323132 10px,
    #242427 20px
  );
  left: 0;
  top: 20px;
  outline: 5px solid #242427;
  transform: rotateX(45deg);
}

@media print {
  .nav {
    display: none;
  }
}

.welcome {
  text-align: left;
}
.welcome h2,
.welcome h3,
.welcome h4 {
  color: #eee;
  font-family: "Cinzel", serif;
  font-size: 150%;
}
.welcome .tagline {
  font-size: 200%;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0;
}

.Character {
  text-align: left;
}
.CharacterDesignerPrompt {
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid #666;
  border-radius: 6px;
  box-shadow: 0 0 3px #eee;
  color: #aad0ee;
  padding: 6px;
  width: 100%;
}
.CharacterResponse {
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(235, 235, 242, 1) 35%,
    rgba(202, 205, 219, 1) 100%
  );
  border-radius: 6px;
  color: #333;
  width: 50%;
  margin: 0 auto;
  padding: 1%;
}
.CharacterResponse h2 {
  color: #aa0000;
}
@media screen and (max-width: 480px) {
  .CharacterResponse {
    width: 100%;
  }
}
.CharacterDesignerButton {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #666;
  border-radius: 6px;
  box-shadow: 0 0 3px #eee;
  color: #666;
  margin: 0 auto;
  padding: 6px;
  text-align: center;
}
.CharacterDesignerButton:hover {
  font-weight: bold;
  color: #ccc;
}
.loading {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/galleriffic/2.0.1/css/loader.gif")
    50% 50% no-repeat;
  display: block;
  padding: 100px 0 10px 0;
  text-align: center;
  width: 100%;
}

.panel {
  display: none;
  position: fixed;
  transition: all 1s linear;
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(235, 235, 242, 1) 35%,
    rgba(202, 205, 219, 1) 100%
  );
  color: #333;
  padding: 1%;
}
.panel.tl {
  border-radius: 0 6px 6px 0;
  top: 0;
  left: 0;
  margin-left: calc(-100% + -100vw);
}
.panel.tr {
  border-radius: 6px 0 0 6px;
  top: 0;
  right: 0;
  margin-right: calc(-100% + -100vw);
}
.panel.bl {
  bottom: 0;
  left: 0;
}
.panel.br {
  bottom: 0;
  right: 0;
}
.panel.tl.on {
  margin-left: 0;
}
.panel.tr.on {
  margin-right: 0;
}
.panel .close {
  content: "X";
  position: absolute;
  top: 0;
}
.panel.tl .close,
.panel.bl .close {
  right: 0;
}
.panel.tr .close,
.panel.br .close {
  left: 0;
}

/*
.play-card {
  border-radius: 1%;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  display: inline-block;
  margin: 1%;
  padding: 1%;
  width: 29%;
}
*/

.play-card {
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  flex: 1 1;
  margin: 1%;
  padding: 1%;
  width: 23vw;
  height: 23vw;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

@media screen and (min-width: 1280px) {
  .play-card {
    width: 28%;
    height: 20vw;
  }
}

@media screen and (max-width: 480px) {
  .play-card {
    width: 40vw;
    height: 40vw;
  }
}

/* This container is needed to position the front and back side */
.play-card-inner {
  position: relative;
  border-radius: 3%;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.play-card:hover .play-card-inner,
.play-card.flip .play-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.play-card-front,
.play-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.play-card-front {
  background: #2d2d2d;
  background-position: 50% 50%;
  border: 3px double #444;
  color: #eeeddd;
}

.play-card-front::before {
  background: #000;
  content: " ";
  display: inline-block;
  height: clamp(10px, 5vh, 60px);
  margin-top: clamp(35%, 20vh, 60%);
  opacity: 0.1;
  transform: rotate(45deg);
  vertical-align: middle;
  width: clamp(10px, 5vh, 60px);
  z-index: 1;
}

.play-card-front-title {
  position: absolute;
  border-bottom: 3px #ccc double;
  border-top: 3px #ccc double;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

/* Style the back side */
.play-card-back {
  background-color: #fff;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: 3px double #444;
  color: #111;
  transform: rotateY(180deg);
}
.play-card-back.reverse {
  rotate: 180deg;
}
.play-card-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 3px 3px 3px;
  background: rgba(245, 245, 255, 0.7);
  border-top: 3px #999 double;
  padding: 1% 0;
}
.play-card-type {
  display: block;
  background: rgba(255, 245, 255, 0.3);
  border-radius: 0 0 3px 3px;
  position: absolute;
  top: 100%;
  right: -2px;
  text-align: left;
  font-size: 50%;
  line-height: 1;
  margin: 0;
  padding: 7px 3px 3px 3px;
  text-shadow: 0 0 3px #ffffff;
}

.Darot {
}
.instructions,
.aiResponse {
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(235, 235, 242, 1) 35%,
    rgba(202, 205, 219, 1) 100%
  );
  border-radius: 6px;
  color: #333;
  margin-bottom: 20px;
  padding: 1%;
  text-align: left;
}
.instructions h3 {
  margin: 0;
}

.instructions ol {
  font-size: 90%;
}
.close-instructions {
  float: right;
  margin-left: 10px;
  margin-bottom: 10px;
}
.board {
  background: #230101;
  border-radius: 6px;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  padding: 1%;
  --border-size: 3px;
  --border-angle: 0turn;
  background-image: conic-gradient(
      from var(--border-angle),
      #213,
      #112 50%,
      #213
    ),
    conic-gradient(from var(--border-angle), transparent 40%, #08d, #f90);
  background-size:
    calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;
  animation: bg-spin 10s linear infinite;
}

.aiInput {
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid #666;
  border-radius: 6px;
  box-shadow: 0 0 3px #eee;
  color: #aad0ee;
  padding: 6px;
  width: 100%;
  height: auto;
  resize: vertical;
  min-height: 15vh;
}
.grow-wrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}
.grow-wrap::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.grow-wrap > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
  /* Identical styling required!! */
  border: 1px solid black;
  padding: 0.5rem;
  font: inherit;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

&:hover {
  animation-play-state: paused;
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}
.resultsButton button {
  border-radius: 6px;
  padding: 8px 12px;
}
.results {
  font-size: 200%;
  text-shadow:
    0px 0px 3px #bbb,
    0px 0px 8px #1b2735;
}

.expectations {
  display: block;
  background: #ccc;
  color: #111;
  padding: 10px 0;
  text-align: left;
}
.expectations img {
  border: 1px solid #112;
  border-radius: 3px;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5);
  clear: left;
  display: inline-block;
  float: left;
  margin-bottom: 1%;
  margin-right: 1vw;
  max-width: 20vw;
  max-height: 25vh;
  width: auto;
  height: auto;
}
.expectations ul {
  margin: 0;
  padding: 0;
}
.expectations li {
  border-top: 1px solid #000;
  clear: both;
  display: block;
  list-style: none;
  margin: 1% 0;
  padding: 2% 1% 3% 1%;
  width: 100%;
}
.expectations li:first-child {
  border-top: 0px solid #000;
}
.expectations li.text-center {
  text-align: center;
}
.expectations .subtle {
  display: block;
  padding-top: 10px;
}
.question {
  color: #230101;
}
.explanation-quote {
  color: #370707;
  display: inline-block;
}
.quote-content {
  font-family: "Satisfy", cursive;
  font-size: 125%;
}

@media screen and (max-width: 480px) {
  .expectations img {
    max-width: 45vw;
  }
}

@media print {
  .nav,
  .board,
  .controls {
    display: none;
  }
  .expectations img {
    margin: 10px auto;
    max-width: 100%;
  }
  .expectations li {
    border: 1px solid #000;
    border-radius: 5px;
    display: inline-block;
    width: 44%;
  }
}

