@import url("https://fonts.googleapis.com/css2?family=Alice&family=Cinzel&family=Mooli&family=Philosopher&family=Satisfy&family=Shadows+Into+Light&display=swap");
* {
  box-sizing: border-box;
}
:root {
  /* Define a few values for general use */
  --pad: 0.5rem; /* generic padding */
  --bd: 3px; /* border size */
}

html {
  height: 100%;
  background: #090a0f radial-gradient(ellipse at top, #1b2735 0%, #090a0f 90%)
    no-repeat;
  /* background: url("https://i.imgur.com/BKruPI8.png") 50% 50% no-repeat; */
  color: #cdcdcd;
  display: grid;
  place-items: center;
}
body {
  background-blend-mode: overlay;
  font-size: calc(0.625vmin + 0.75rem); /* Responsive font */
  /*
  font-family: "Alice", serif;
  font-family: "Cinzel", serif;
  font-family: "Philosopher", sans-serif;
  font-family: "Satisfy", cursive;
  font-family: "Shadows Into Light", cursive;
  */
  font-family: "Mooli", sans-serif;
  margin: 0; /* Remove HTML default spacing */
  min-width: 100%;
  max-width: 100%;
  text-align: center;
}
h1,
h2 {
  color: #eee;
  font-family: "Cinzel", serif;
  font-size: 250%;
  line-height: 1;
  margin-bottom: 0;
}
#root {
  display: inline-block;
  margin: 0 auto;
  overflow-x: hidden;
  padding: calc(2 * var(--pad));
  text-align: center;
}
.App {
  /* max-width: 1280px; */
  max-width: 100vw;
  width: 100%;
  text-align: center;
}
.logo {
  font-family: "Alice", serif;
  font-size: 200%;
}
.tagline {
  color: #aaa;
  font-family: "Satisfy", cursive;
  font-size: 100%;
  padding-bottom: 20px;
}
.center {
  text-align: center;
}
.text-lg {
  font-size: 125%;
}
.subtle {
  opacity: 0.6;
}
.col-6 {
  display: inline-grid;
  width: 50%;
}
@media screen and (max-width: 480px) {
  .col-6 {
    display: block;
    width: 100%;
  }
}
a,
button {
  cursor: pointer;
}
a {
  text-decoration: none;
}
a:link {
  color: #68a9ea;
}
a:visited {
  color: #dd7575;
}
footer {
  display: block;
  width: 100%;
  min-width: 96vw;
  max-width: 100vw;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    #12100e 64%,
    #090a0f 90%
  );
  max-height: 40vh;
  min-height: 10vh;
  font-size: 85%;
  margin: 20vh auto 0 auto;
  padding-bottom: 20px;
  padding-top: 30px;
}
.name_field {
  font-size: 15pt;
}
.tagline {
  color: #aaa;
}
.plan-board {
  text-align: left;
}
.plan-board li {
  padding-bottom: 20px;
}
.overlay {
  background: rgba(0, 0, 0, 0.2);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vh;
  height: 100vh;
}
.overlay-modal {
  margin: auto auto;
  display: block;
  overflow: auto;
  width: 60vh;
  height: 60vh;
}
.version {
  font-size: 50%;
  color: #2b4162;
}
.hide,
.print {
  display: none;
}
@media print {
  .logo.hide,
  .print {
    display: inline-block;
  }
}
