.Character {
  text-align: left;
}
.CharacterDesignerPrompt {
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid #666;
  border-radius: 6px;
  box-shadow: 0 0 3px #eee;
  color: #aad0ee;
  padding: 6px;
  width: 100%;
}
.CharacterResponse {
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(235, 235, 242, 1) 35%,
    rgba(202, 205, 219, 1) 100%
  );
  border-radius: 6px;
  color: #333;
  width: 50%;
  margin: 0 auto;
  padding: 1%;
}
.CharacterResponse h2 {
  color: #aa0000;
}
@media screen and (max-width: 480px) {
  .CharacterResponse {
    width: 100%;
  }
}
.CharacterDesignerButton {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #666;
  border-radius: 6px;
  box-shadow: 0 0 3px #eee;
  color: #666;
  margin: 0 auto;
  padding: 6px;
  text-align: center;
}
.CharacterDesignerButton:hover {
  font-weight: bold;
  color: #ccc;
}
.loading {
  background: url("https://cdnjs.cloudflare.com/ajax/libs/galleriffic/2.0.1/css/loader.gif")
    50% 50% no-repeat;
  display: block;
  padding: 100px 0 10px 0;
  text-align: center;
  width: 100%;
}
