.Darot {
}
.instructions,
.aiResponse {
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(235, 235, 242, 1) 35%,
    rgba(202, 205, 219, 1) 100%
  );
  border-radius: 6px;
  color: #333;
  margin-bottom: 20px;
  padding: 1%;
  text-align: left;
}
.instructions h3 {
  margin: 0;
}

.instructions ol {
  font-size: 90%;
}
.close-instructions {
  float: right;
  margin-left: 10px;
  margin-bottom: 10px;
}
.board {
  background: #230101;
  border-radius: 6px;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  padding: 1%;
  --border-size: 3px;
  --border-angle: 0turn;
  background-image: conic-gradient(
      from var(--border-angle),
      #213,
      #112 50%,
      #213
    ),
    conic-gradient(from var(--border-angle), transparent 40%, #08d, #f90);
  background-size:
    calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;
  animation: bg-spin 10s linear infinite;
}

.aiInput {
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid #666;
  border-radius: 6px;
  box-shadow: 0 0 3px #eee;
  color: #aad0ee;
  padding: 6px;
  width: 100%;
  height: auto;
  resize: vertical;
  min-height: 15vh;
}
.grow-wrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}
.grow-wrap::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.grow-wrap > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
  /* Identical styling required!! */
  border: 1px solid black;
  padding: 0.5rem;
  font: inherit;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

&:hover {
  animation-play-state: paused;
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}
.resultsButton button {
  border-radius: 6px;
  padding: 8px 12px;
}
.results {
  font-size: 200%;
  text-shadow:
    0px 0px 3px #bbb,
    0px 0px 8px #1b2735;
}

.expectations {
  display: block;
  background: #ccc;
  color: #111;
  padding: 10px 0;
  text-align: left;
}
.expectations img {
  border: 1px solid #112;
  border-radius: 3px;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5);
  clear: left;
  display: inline-block;
  float: left;
  margin-bottom: 1%;
  margin-right: 1vw;
  max-width: 20vw;
  max-height: 25vh;
  width: auto;
  height: auto;
}
.expectations ul {
  margin: 0;
  padding: 0;
}
.expectations li {
  border-top: 1px solid #000;
  clear: both;
  display: block;
  list-style: none;
  margin: 1% 0;
  padding: 2% 1% 3% 1%;
  width: 100%;
}
.expectations li:first-child {
  border-top: 0px solid #000;
}
.expectations li.text-center {
  text-align: center;
}
.expectations .subtle {
  display: block;
  padding-top: 10px;
}
.question {
  color: #230101;
}
.explanation-quote {
  color: #370707;
  display: inline-block;
}
.quote-content {
  font-family: "Satisfy", cursive;
  font-size: 125%;
}

@media screen and (max-width: 480px) {
  .expectations img {
    max-width: 45vw;
  }
}

@media print {
  .nav,
  .board,
  .controls {
    display: none;
  }
  .expectations img {
    margin: 10px auto;
    max-width: 100%;
  }
  .expectations li {
    border: 1px solid #000;
    border-radius: 5px;
    display: inline-block;
    width: 44%;
  }
}
