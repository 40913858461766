/*
.play-card {
  border-radius: 1%;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  display: inline-block;
  margin: 1%;
  padding: 1%;
  width: 29%;
}
*/

.play-card {
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  flex: 1;
  margin: 1%;
  padding: 1%;
  width: 23vw;
  height: 23vw;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

@media screen and (min-width: 1280px) {
  .play-card {
    width: 28%;
    height: 20vw;
  }
}

@media screen and (max-width: 480px) {
  .play-card {
    width: 40vw;
    height: 40vw;
  }
}

/* This container is needed to position the front and back side */
.play-card-inner {
  position: relative;
  border-radius: 3%;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.play-card:hover .play-card-inner,
.play-card.flip .play-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.play-card-front,
.play-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.play-card-front {
  background: #2d2d2d;
  background-position: 50% 50%;
  border: 3px double #444;
  color: #eeeddd;
}

.play-card-front::before {
  background: #000;
  content: " ";
  display: inline-block;
  height: clamp(10px, 5vh, 60px);
  margin-top: clamp(35%, 20vh, 60%);
  opacity: 0.1;
  transform: rotate(45deg);
  vertical-align: middle;
  width: clamp(10px, 5vh, 60px);
  z-index: 1;
}

.play-card-front-title {
  position: absolute;
  border-bottom: 3px #ccc double;
  border-top: 3px #ccc double;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

/* Style the back side */
.play-card-back {
  background-color: #fff;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: 3px double #444;
  color: #111;
  transform: rotateY(180deg);
}
.play-card-back.reverse {
  rotate: 180deg;
}
.play-card-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 3px 3px 3px;
  background: rgba(245, 245, 255, 0.7);
  border-top: 3px #999 double;
  padding: 1% 0;
}
.play-card-type {
  display: block;
  background: rgba(255, 245, 255, 0.3);
  border-radius: 0 0 3px 3px;
  position: absolute;
  top: 100%;
  right: -2px;
  text-align: left;
  font-size: 50%;
  line-height: 1;
  margin: 0;
  padding: 7px 3px 3px 3px;
  text-shadow: 0 0 3px #ffffff;
}
