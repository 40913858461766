.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 60px;
}
.navIcon {
  background-size: contain;
  border-bottom: 2px solid #ffffff;
  margin: 20px auto;
  max-width: 100%;
  width: 80%;
  height: 45%;
}
#NavWelcome .navIcon {
  background:
    url(../images/come.png) 50% 50% no-repeat,
    radial-gradient(ellipse, #1b2735 0%, rgba(0, 0, 0, 0) 50%) no-repeat;
  background-size: contain;
}
#NavDarot .navIcon {
  background:
    url(../images/meditation.png) 50% 50% no-repeat,
    radial-gradient(ellipse, #1b2735 0%, rgba(0, 0, 0, 0) 50%) no-repeat;
  background-size: contain;
}
#NavCharacter .navIcon {
  background:
    url(../images/archetype.png) 50% 50% no-repeat,
    radial-gradient(ellipse, #1b2735 0%, rgba(0, 0, 0, 0) 50%) no-repeat;
  background-size: contain;
}
#NavBoards .navIcon {
  background:
    url(../images/plan.png) 50% 50% no-repeat,
    radial-gradient(ellipse, #1b2735 0%, rgba(0, 0, 0, 0) 50%) no-repeat;
  background-size: contain;
}
@media screen and (max-width: 1280px) {
  nav.nav {
    zoom: 0.85;
  }
  .nav.zoom {
    zoom: 0.5;
  }
}
@media screen and (max-width: 960px) {
  nav.nav {
    zoom: 0.65;
  }
  .nav.zoom {
    zoom: 0.4;
  }
}
@media screen and (max-width: 800px) {
  nav.nav {
    zoom: 0.6;
  }
}
@media screen and (max-width: 700px) {
  nav.nav {
    zoom: 0.5;
  }
}
@media screen and (max-width: 640px) {
  nav.nav {
    zoom: 0.45;
  }
}
@media screen and (max-width: 520px) {
  nav.nav {
    zoom: 0.4;
  }
}
@media screen and (max-width: 480px) {
  nav.nav {
    zoom: 0.35;
  }
  .nav.zoom {
    zoom: 0.25;
  }
}
@media screen and (max-width: 320px) {
  nav.nav {
    zoom: 0.3;
  }
  .nav.zoom {
    zoom: 0.25;
  }
}

.nav.zoom .door-mat {
  display: none;
}
.homeLogo {
  color: #ccc;
  font-family: "Alice", serif;
  font-size: 300%;
  width: 25%;
}
/*
.nav.zoom .homeLogo {
  display: none;
}
.nav.zoom:before {
  color: #ccc;
  content: "HH";
  display: inline-block;
  font-family: "Alice", serif;
  font-size: 600%;
  text-align: center;
  width: 100%;
}
*/
.nav-items {
  list-style: none;
  display: flex;
  text-align: center;
  width: auto;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  padding-left: 0;
}
.nav-items > li {
  cursor: pointer;
  display: inline-flex;
  position: relative;
}

.door {
  position: relative;
  width: 170px;
  height: 270px;
  margin: 0 30px;
  cursor: pointer;
  transform-style: preserve-3d;
  transform: perspective(2500px);
  box-shadow:
    0 1px 0 0 rgba(255, 192, 0, 0.25),
    0 -1px 0 0 rgba(255, 64, 0, 0.25),
    1px 0 0 0 rgba(255, 192, 0, 0.25),
    -1px 0 0 0 rgba(255, 64, 0, 0.25),
    1px -1px 0 0 rgba(255, 128, 0, 0.5),
    -1px 1px 0 0 rgba(255, 128, 0, 0.5),
    1px 1px 0 0 rgba(255, 255, 0, 0.75),
    -1px -1px 0 0 rgba(255, 0, 0, 0.75);
}
.door-front {
  width: 170px;
  height: 270px;
  overflow: hidden;
  transform-origin: left;
  box-shadow: 30px 0 50px rgba(0, 0, 0, 0.2);
  position: absolute;
  background-color: #6a4317;
  z-index: 1;
  transition: 0.5s;
}
.door-front:before,
.door-front:after {
  content: "";
  position: absolute;
  background-color: #603c14;
  width: 105px;
  height: 75px;
  border: 8px ridge #583523;
  left: 24px;
  rotate: 180;
}

.door-front:before {
  top: 25px;
}

.door-front:after {
  top: 155px;
}
.knob {
  position: absolute;
  width: 20px;
  height: 30px;
  background-color: #dae1ec;
  top: 122px;
  left: 145px;
  border-radius: 2px;
}

.knob:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  background-color: #e5e6d6;
  width: 18px;
  height: 18px;
  left: -1px;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}

.knob:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 7px;
  background-color: #333;
  top: 20px;
  left: 8.5px;
}

.door:hover .door-front {
  transform: rotateY(-160deg);
}

.door-back {
  position: relative;
  background: linear-gradient(
    0deg,
    rgb(67, 21, 16) 13%,
    rgba(73, 88, 103, 0.5) 13%,
    rgba(79, 100, 124, 0.5) 15%,
    rgba(2, 14, 30, 0.756) 15%
  );
  color: #eee;
  font-size: 125%;
  font-weight: bold;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 10px solid #192430;
}

.door-back.door-back-trans-1 {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
}

.door-back.door-back-trans-2 {
  background: linear-gradient(-50deg, #e9ef3a, #a06bb9, #165b22, #b4820b);
  background-size: 500% 500%;
  animation: gradient 10s ease infinite;
}

.door-back.door-back-trans-3 {
  background: linear-gradient(-40deg, #4e060c, #d7d04f, #27052d, #0d0534);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
}
.door-back:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  content: " ";
  width: 100%;
  height: 100%;
  background: radial-gradient(
      ellipse 96% 20% at top,
      rgba(178, 217, 230, 0.8) 0%,
      rgba(158, 197, 210, 0) 50%
    )
    no-repeat;
  background-size: contain;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.door-mat {
  position: relative;
  perspective: 200px;
}

.door-mat:before {
  content: "Welcome";
  position: absolute;
  width: 170px;
  height: 23px;
  padding-top: 7px;
  color: rgba(180, 180, 180, 0.5);
  font-family: "Alice", serif;
  font-weight: bold;
  text-shadow: 0 0 2px #111;
  background: repeating-linear-gradient(
    #323132,
    #242427 10px,
    #323132 10px,
    #242427 20px
  );
  left: 0;
  top: 20px;
  outline: 5px solid #242427;
  transform: rotateX(45deg);
}

@media print {
  .nav {
    display: none;
  }
}
